<template>
	<div class="page">
		<softWare></softWare>
		<commonProblem></commonProblem>
	</div>
</template>

<script>
	import softWare from "./components/softWare.vue"
	import commonProblem from "./components/commonProblem.vue"
	// import {downloadCenter} from "@/api/index.js"
	export default {
		components: {
			softWare,
			commonProblem
		},
		data() {
			return {

			}
		},
		mounted() {
			this.getInfo()
		},

		methods: {
			getInfo() {
				// downloadCenter().then(res => {
				// 	if (res.code == "0000") {
				// 		console.log(res)
				// 	}
				// })
			}
		},
	}
</script>

<style lang="scss">

</style>
