<template>
	<div>
		<div style="margin-top: 20px;display: flex;flex-wrap: wrap;flex-direction: row;justify-content: space-between;">

			<div style="width: 23%;margin-top: 10px" v-for="(item,index) in softWareData" :key="index">
				<oneSoftWare :infoDetails="item"></oneSoftWare>
			</div>
		</div>
	</div>
</template>

<script>
	import oneSoftWare from "./oneSoftWare.vue"
	export default {
		components: {
			oneSoftWare
		},
		data() {
			return {
				softWareData: [{
						isServer: '0',
						tagName: "编程语言",
						name: "Scratch",
						details: "Scratch是麻省理工学院开发的一款简易图形化编程工具。这个软件的开发团队称为“终身幼儿园团队”（Lifelong Kindergarten Group）。几乎所有的孩子都会一眼喜欢上这个软件。建立起做编程的欲望。",
						imgUrl: require('@/assets/downloadImage/Scratch1.png'),
						downLodeType: 1,
						downLodeType32: 1,
						isOtherVersion: '1'
					},
					// {
					// 	isServer: '0',
					// 	tagName: "数据库",
					// 	name: "MongoDB",
					// 	details: "MongoDB是一个基于分布式文件存储的数据库。由C++语言编写。旨在为WEB应用提供可扩展的高性能数据存储解决方案。",
					// 	imgUrl: require('@/assets/downloadImage/mongoDB.png'),
					// 	isOtherVersion: '1'
					// },
					{
						isServer: '0',
						tagName: "编辑器",
						name: "Pycharm",
						details: "PyCharm是一种Python IDE，带有一整套可以帮助用户在使用Python语言开发时提高其效率的工具，比如调试、语法高亮、Project管理、代码跳转、智能提示、自动完成、单元测试、版本控制。此外，该IDE提供了一些高级功能，以用于支持Django框架下的专业Web开发。",
						imgUrl: require('@/assets/downloadImage/pycharm.png'),
						downLodeType: 2,
						downLodeType32: 2,
						isOtherVersion: '1'
					},
					{
						isServer: '0',
						tagName: "编程环境",
						name: "Python",
						details: "Python由荷兰数学和计算机科学研究学会的Guido van Rossum 于1990 年代初设计，作为一门叫做ABC语言的替代品。Python提供了高效的高级数据结构，还能简单有效地面向对象编程。Python语法和动态类型，以及解释型语言的本质，使它成为多数平台上写脚本和快速开发应用的编程语言，随着版本的不断更新和语言新功能的添加，逐渐被用于独立的、大型项目的开发。",
						imgUrl: require('@/assets/downloadImage/python.png'),
						downLodeType: 4,
						downLodeType32:3,
						isOtherVersion: '0'
					},
					{
						isServer: '0',
						tagName: "浏览器",
						name: "谷歌浏览器",
						details: "Google Chrome是一款由Google公司开发的网页浏览器，该浏览器基于其他开源软件撰写，包括WebKit，目标是提升稳定性、速度和安全性，并创造出简单且有效率的使用者界面。",
						imgUrl: require('@/assets/downloadImage/google.png'),
						downLodeType: 6,
						downLodeType32: 5,
						isOtherVersion: '0'
					},
					{
						isServer: '0',
						tagName: "编辑器",
						name: "dev-CPP",
						details: "Dev-C++（或者叫做 Dev-Cpp）是 Windows 环境下的一个轻量级 C/C++ 集成开发环境（IDE）。它是一款自由软件，遵守GPL许可协议分发源代码。它集合了功能强大的源码编辑器、MingW64/TDM-GCC 编译器、GDB 调试器和 AStyle 格式整理器等众多自由软件，适合于在教学中供 C/C++语言初学者使用，也适合于非商业级普通开发者使用。",
						imgUrl: require('@/assets/downloadImage/devCpp.png'),
						downLodeType: 7,
						downLodeType32: 7,
						isOtherVersion: '1'
					},
					{
						isServer: '0',
						tagName: "远程协助",
						name: "向日葵",
						details: "向日葵远程控制是一款提供远程控制服务的软件。向日葵远程控制支持主流操作系统Windows、Linux、Mac、Android、iOS跨平台协同操作，在任何可连入互联网的地点，都可以轻松访问和控制安装了向日葵远程控制客户端的设备。整个远控过程，可通过浏览器直接进行，无需再安装软件。",
						imgUrl: require('@/assets/downloadImage/xrk.png'),
						downLodeType: 9,
						downLodeType32: 8,
						isOtherVersion: '0'
					},
					{
						isServer: '0',
						tagName: "压缩文件",
						name: "360压缩",
						details: "360压缩是新一代的压缩软件，永久免费。360压缩相比传统压缩软件更快更轻巧，支持解压主流的rar、zip、7z、iso等多达40种压缩文件。360压缩内置云安全引擎，可以检测木马，更安全。大幅简化了传统软件的繁琐操作，还改进了超过20项的使用细节，拥有全新的界面。360压缩的主要特点是快速轻巧、兼容性好、更安全、更漂亮，而且是永久免费的。",
						imgUrl: require('@/assets/downloadImage/yasuo.png'),
						downLodeType: 10,
						downLodeType32: 10,
						isOtherVersion: '1'
					},
					{
						isServer: '0',
						tagName: "turtle",
						name: "turtle",
						details: "海龟渲染器，和各种三维软件都有着良好的结合。功能强大，使用方便。该渲染器的特色在于其渲染速度可以优海龟渲染器，和各种三维软件都有着良好的结合。功能强大，使用方便。化得非常快，相比起mental ray来说，这是他的一大优点。尤其是在全局光与final gather联用的时候效果更是明显。",
						imgUrl: require('@/assets/downloadImage/turtle.png'),
						downLodeType: 11,
						downLodeType32: 11,
						isOtherVersion: '1'
					},
					{
						isServer: '0',
						tagName: "腾讯会议",
						name: "腾讯会议",
						details: "腾讯会议是腾讯云旗下的一款音视频会议产品，于2019年12月底上线。具有300人在线会议、全平台一键接入、音视频智能降噪、美颜、背景虚化、锁定会议、屏幕水印等功能。该软件提供实时共享屏幕、支持在线文档协作",
						imgUrl: require('@/assets/downloadImage/TencentMeeting.png'),
						downLodeType: 12,
						downLodeTypeMac: 13,
						isOtherVersion: '2'
					},

					// {
					// 	isServer: '0',
					// 	tagName: "腾讯会议",
					// 	name: "腾讯会议",
					// 	details: "XMind 是一款非常实用的商业思维导图软件，应用全球最先进的Eclipse RCP 软件架构，全力打造易用、高效的可视化思维软件，强调软件的可扩展、跨平台、稳定性和性能，致力于使用先进的软件技术帮助用户真正意义上提高生产率。",
					// 	imgUrl: require('@/assets/downloadImage/TencentMeeting.png'),
					// 	downLodeType: 12,
					// 	downLodeTypeMac: 13,
					// 	isOtherVersion: '1'
					// },

					// {
					// 	isServer: '1',
					// 	tagName: "技术支持",
					// 	name: "360压缩",
					// 	details: "360压缩是新一代的压缩软件，永久免费。360压缩相比传统压缩软件更快更轻巧，支持解压主流的rar、zip、7z、iso等多达40种压缩文件。360压缩内置云安全引擎，可以检测木马，更安全。大幅简化了传统软件的繁琐操作，还改进了超过20项的使用细节，拥有全新的界面。360压缩的主要特点是快速轻巧、兼容性好、更安全、更漂亮，而且是永久免费的。",
					// 	imgUrl: require('@/assets/favicon.ico'),
					// 	isOtherVersion: '0'
					// },
				]
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
