<template>
	<div style="margin-top: 30px;">
		<p style="font-size:26px;text-align: center;">常见问题</p>
		<el-collapse v-model="activeNames" @change="handleChange" style="">
			<el-collapse-item title="1.在哪里上课" name="1">
				<div>在腾讯课堂/腾讯会议客户端上课，请于上课前用电脑下载安装好其中一个客户端。</div>
			</el-collapse-item>
			<el-collapse-item title="2.孩子没接触过电脑、不会指法，可以学习吗？" name="2">
				<div>可以，老师会带着孩子熟悉电脑和键盘。</div>
			</el-collapse-item>
			<el-collapse-item title="3.可以用手机或者iPad或者苹果电脑上课吗？" name="3">
				<div>可以的，手机或者iPad可以听直播课，不过因为我们学习的是真正的编程代码，需要孩子在电脑上自己敲写代码和运行程序，所以电脑是必备的哦~</div>
			</el-collapse-item>
			<el-collapse-item title="4. 上课前需要准备什么？" name="4">
				<div>一台Win7及以上操作系统的电脑（笔记本、台式机皆可）、麦克风注意：仅限Windows系统电脑，Win7、Win8、Win10都可，暂不支持XP。用电脑安装腾讯会议客户端。</div>
			</el-collapse-item>
			<el-collapse-item title="5. 孩子在家可以操作吗？需要家长陪同吗？" name="5">
				<div>孩子是可以独立完成的。孩子有任何疑问都可以微信、QQ或电话联系班主任。家长如果时间允许，也可以陪伴孩子一起。</div>
			</el-collapse-item>
			<el-collapse-item title="6.是直播课还是录播课？错过直播课怎么办？" name="6">
				<div>直播课。孩子有任何疑问都可以微信、QQ或电话联系班主任。</div>
			</el-collapse-item>
			<el-collapse-item title="7.临时有事，可以请假吗？" name="7">
				<div>可以的，提前半天联系班主任或助教或授课老师请假即可。</div>
			</el-collapse-item>
			<el-collapse-item title="8.遇到问题如何解决？" name="8">
				<div>孩子上课过程中若遇到问题，可以在课堂的讨论区向老师提问。同时，每位孩子都会有一位专属班主任在线全程陪同孩子学习，孩子有任何疑问都可以随时询问班主任。未购课的学员，到“我-在线客服”询问课程顾问
				</div>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeNames: []
			}
		},
		methods: {
			handleChange(val) {
				// console.log(val);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.alertDetails {
		margin-top: 20px;

		/deep/ .el-alert {
			margin-top: 10px;
		}

		/deep/ .el-alert--info {
			background-color: #CCCCCC;
			color: #808080
		}
	}

	/deep/ .el-collapse-item__header {
		background-color: #cccccc;
		border-radius: 10px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom:none
	}
	/deep/ .is-active{
		// border-top-left-radius: 10px;
		// border-top-right-radius: 10px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		
	}
	/deep/ .el-collapse-item__content{
		padding-left: 20px;
		padding-right: 20px;
		background-color: #cccccc;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	
	/deep/ .el-collapse-item{
		margin-top: 30px;
	}
</style>
